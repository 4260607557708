import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Tabs,
  Tab
} from '@mui/material';

import {
  TabList,
  TabPanel,
  TabContext
} from '@mui/lab'


import { CheckCircle, Cancel } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [view, setView] = useState("1");
  const [melkteData, setMelkteData] = useState([])
  const [csvData, setCsvData] = useState([]);
  const [csvName, setCsvName] = useState('');
  const [date, setDate] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [matchPercentage, setMatchPercentage] = useState(0);
  const [statusPercentage, setStatusPercentage] = useState(0);


  async function authenticate() {
    try {
      const authResponse = await axios.post('https://phonefilter-api.bitappstech.com/proxy/api/authenticate', {
        username: "Admin",
        password: "admin",
        rememberMe: false
      });
      return authResponse.data.id_token; // Return the token from the response
    } catch (err) {
      setError("Authentication failed. Please check your credentials.");
      throw new Error("Authentication failed");
    }
  }




  // async function fetchData() {
  //   const response = await axios.get('https://phonefilter-api.bitappstech.com/proxy/api/phone-lists', {
  //     params: {
  //       'phoneGroupId.equals': 1,
  //       page: 0,
  //       size: 100000,
  //       sort: 'id,asc'
  //     }
  //   });

  //   setMelkteData(response);
  // }


  async function fetchData() {
    try {
      setLoading(true);
      setError(null);

      // Step 1: Fetch the total count
      const token = await authenticate();

      // Step 3: Fetch the total count with the token
      const countResponse = await axios.get('https://phonefilter-api.bitappstech.com/proxy/api/phone-lists/count', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          'phoneGroupId.equals': 1,
        }
      });
      const totalCount = countResponse.data;

      // Step 2: Calculate the number of pages
      const pageSize = 2000;
      const totalPages = Math.ceil(totalCount / pageSize);

      let allData = [];

      // Step 3: Fetch all pages
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('https://phonefilter-api.bitappstech.com/proxy/api/phone-lists', {
          params: {
            'phoneGroupId.equals': 1,
            page: page,
            size: pageSize,
            sort: 'id,asc'
          }
        });

        allData = allData.concat(response.data); // Merge the current page data with allData
      }

      // Step 4: Set all the data to state
      setMelkteData(allData);

    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    const today = new Date().toISOString().split('T')[0];
    setDate(today);

  }, []);

  const handleChange = (event, newValue) => {
    setView(newValue);
  };

  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\s+/g, '').slice(-9);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCsvName(file.name);
    Papa.parse(file, {
      complete: (result) => {
        const cleanedData = result.data.map(row => ({
          ...row,
          phoneNumber: cleanPhoneNumber(row.phoneNumber)
        }));
        setCsvData(cleanedData);
        setUploadFinished(true);
        setError(null);
      },
      header: true
    });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const filterByDate = (data, date) => {
    return data.filter(item => item.registrationTime.startsWith(date));
  };

  //create a function that takes data and date and gets all the data that's after the given date
  const filterAfterDate = (data, date) => {
    return data.filter(item => new Date(item.registrationTime) >= new Date(date + "T00:00:00Z"));
  }

  const todayView = () => {
    try {

      if (melkteData.length && melkteData.length <= 0) return "";

      const todayData = filterByDate(melkteData, new Date().toJSON().split('T')[0]).map(item => ({
        ...item,
        phone: cleanPhoneNumber(item.phone)
      }));

      return <>
        <Typography >Total Count : {todayData.length}</Typography>
        {todayData.map((result, index) => (


          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{result.phone}</TableCell>
            <TableCell align="center">{result.status ? 'True' : 'False'}</TableCell>
          </TableRow>
        ))}
      </>
    } catch (error) {
      console.error('Error rendering today\'s view ', error);
    }
  }

  const handleSearch = () => {
    //check if the csv file is uploaded
    if (csvData.length === 0) {
      setError('Please upload a CSV file first!');
      return;
    }

    setLoading(true);
    try {

      const apiData = filterAfterDate(melkteData, date).map(item => ({
        ...item,
        phone: cleanPhoneNumber(item.phone)
      }));

      const results = csvData.map((csvRow) => {

        const match = apiData.some(apiRow => apiRow.phone === csvRow.phoneNumber);

        const status = apiData.find(apiRow => apiRow.phone === csvRow.phoneNumber)?.status || false;

        return { ...csvRow, match, status };
      });

      setResults(results);

      const matchedCount = results.filter(result => result.match).length;
      const statusTrueCount = results.filter(result => result.status).length;
      setMatchPercentage((matchedCount / results.length) * 100);
      setStatusPercentage((statusTrueCount / results.length) * 100);
    } catch (error) {
      console.error('Error fetching data from API', error);
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setUploadFinished(false);
    setError(null);
  };

  // if (melkteData.length == 0) return "Loading Data..."

  return (
    <Container maxWidth="md">
      <TabContext value={view}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="CSV Upload" value="1" />
            <Tab label="Today" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box my={4}>
            <Typography variant="h4" component="h1" gutterBottom>
              Phone Number Matcher
            </Typography>
            <Box my={2} display={'flex'}>
              <Button variant="contained" component="label" style={{ "marginRight": "2rem" }}>
                Upload CSV
                <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
              </Button>

              {csvName && <Typography>📄 {csvName} </Typography>}
            </Box>
            <Box my={2}>
              <TextField
                label="Search After"
                type="date"
                value={date}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
            <Box my={2}>
              <Button variant="contained" color="primary" onClick={handleSearch} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Search'}
              </Button>
            </Box>
            {results.length > 0 && (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell align="center">Match</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {results.map((result, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{result.phoneNumber}</TableCell>
                          <TableCell align="center">
                            {result.match ? <CheckCircle color="success" /> : <Cancel color="error" />}
                          </TableCell>
                          <TableCell align="center">{result.status ? 'True' : 'False'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box my={2}>
                  <Typography variant="h6">Match Percentage: {matchPercentage.toFixed(2)}%</Typography>
                  <Typography variant="h6">Status True Percentage: {statusPercentage.toFixed(2)}%</Typography>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {todayView()}
                </TableBody>
              </Table>
            </TableContainer>
            <Box my={2}>

            </Box>
          </>
        </TabPanel>
      </TabContext>


      <Snackbar open={uploadFinished} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          CSV upload finished successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={error != null} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          Please upload a CSV file first!
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
